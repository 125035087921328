import React from 'react';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { Provider as StyletronProvider } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { DarkTheme, BaseProvider, LightTheme } from 'baseui';
import { Block } from 'baseui/block';
import { H1, Paragraph3 } from 'baseui/typography';
import { Checkbox, STYLE_TYPE, LABEL_PLACEMENT } from 'baseui/checkbox';
import { 
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

const engine = new Styletron();

const blockProps = {
  backgroundColor: 'background',
  width: '100vw',
  height: '100vh',
  overflow: 'hidden'
};

const themes = {
  'dark-theme': DarkTheme,
  'light-theme': LightTheme
}

class App extends React.Component {
  state = {
    theme: themes['light-theme']
  }

  componentDidMount() {
    const themeName = localStorage.getItem('theme-name');
    if (themeName in themes) {
      this.setState({ theme: themes[themeName] });
    }
  }

  changeTheme = (e) => {
    const theme = e.target.checked ? themes['dark-theme'] : themes['light-theme'];
    localStorage.setItem('theme-name', theme.name);
    this.setState({ theme });
  }

  render() {
    return (
      <StyletronProvider value={engine}>
        <BaseProvider theme={this.state.theme}>
          <Router>
            <Switch>
              <Route exact path='/'>
                <Block {...blockProps}>
                  <div className="App">
                    <header>
                      <div className="header-bg" style={{ backgroundImage: this.state.theme === themes['dark-theme'] ? 'linear-gradient(#261bc9, #0f0a55)' : 'linear-gradient(#ff9d2f, #ff6126)' }}>
                        <div className="test">
                          <Checkbox
                            checked={this.state.theme === DarkTheme}
                            checkmarkType={STYLE_TYPE.toggle_round}
                            onChange={this.changeTheme}
                            labelPlacement={LABEL_PLACEMENT.right}
                          >
                            <i class="fas fa-moon" style={{ color: this.state.theme.colors.primary }} />
                          </Checkbox>
                        </div>

                      </div>
                    </header>
                    <H1>Jimmy Wu</H1>
                    <Paragraph3>please accept my pull request</Paragraph3>
                  </div>
                </Block>
              </Route>
              <Route path='*'>
                <Redirect to='/' />
              </Route>
            </Switch>
          </Router>
        </BaseProvider>
      </StyletronProvider>
    );
  }

}

export default App;
